<template>
  <div class="Yuyan">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>留学生签证</span>知识手册（中国）</h3>
          <p>International Student Service</p>
        </div>
        <div class="tit">
          签证的相关规定
        </div>
        <p class="_t">
          关于留学签证的规定
        </p>
        <p>国际学生应遵守《中华人民共和国出境入境法》的规定，及时办理签证延期或者申请居留证件手续。</p>
        <p>国际学生需持因私普通护照和 X1 或 X2 签证注册学习，免签入境的学生不能办理注册手续。</p>
        <p>关于签证的期限：</p>
        <p class="_ml">申请签证前需按时足额缴纳学费，签证延长期限和学费缴纳期限一致</p>
        <p class="_b">示例</p>
        <p class="_ml">非学历生：</p>
        <p class="_ml2">2018年3月申请，缴费一学期，期限至2018年7月31日</p>
        <p class="_ml2">2018年3月申请，缴费两学期，期限至2019年1月31日</p>
        <p class="_ml">学历生：</p>
        <p class="_ml2">2018年3月申请，缴费一学期，期限至2018年9月30日</p>
        <p class="_ml2">2018年3月申请，缴费两学期，期限至2019年3月30日</p>
        <p class="_ml">预计2018年6月毕业的学生：2018年3月申请，缴费一学期，期限至2018年7月31日</p>
        <p class="_ml">预计2019年1月毕业的学生：2018年3月申请，缴费两学期，期限至2019年1月31日</p>
        <p class="_ml">以此类推。</p>
        <p>持X2签证入境且只学习一学期的学生可以申请增加入境次数1次或者2次，不能申请居留证件。</p>
        <p>护照即将到期的学生请提前联系贵国驻华使馆询问新护照办理的事宜。</p>
        <p>签证是国际学生正常在华学习和生活的前提，请于来华后尽快完成签证办理手续，以免造成不良后果。</p>
        <div class="tit">
          教您看懂中国签证
        </div>
        <p class="_t">
          X1 签证 X1VISA
        </p>
        <p>X1 签证为中国驻外使馆签发，针对在中国境内长期（超过 180 天）学习的人员，有效期为 30 天，在入境后 30 天内必须办理居留证件。</p>
        <p>000 表示入境后可停留 30 天。</p>
        <div class="imgbox">
          <img src="@/assets/visa001.jpg"
               alt="">
        </div>
        <p class="_t">境外签发的 X2 签证</p>
        <p>X2 签证为中国驻外使馆签发，针对在中国境内短期（不超过 180 天）学习的人员，有效期自入境之日起算，可停留时间为箭头所指示的天数。</p>
        <div class="imgbox">
          <img src="@/assets/visa003.jpg"
               alt="">
        </div>
        <p class="_t">境内换发的 X2 签证 X2 Visa Renewed in China</p>
        <p>红色圆圈表示在“有效期至”之前，可以入境的次数。</p>
        <p>蓝色圆圈表示，如果离境后再次入境，可以停留的天数。</p>
        <div class="imgbox">
          <img src="@/assets/visa004.jpg"
               alt="">
        </div>
        <p class="_t">居留证件</p>
        <p>居留证件为北京市公安局出入境管理处签发，可以多次出入境，有效期为红色圆圈内显示日期。</p>
        <div class="imgbox">
          <img src="@/assets/visa005.jpg"
               alt="">
        </div>
        <div class="tit">健康检查的办理</div>
        <p class="_b">持 X2 签证入境且只学习一个学期的国际学生不需要做健康检查。</p>
        <p class="_b">持 X1 签证入境的国际学生需要做健康检查。</p>
        <p class="_b">持境外签发的短期类签证且在中国境内长期（超过 180 天）学习的人员需要做健康检查。</p>
        <p>在本国做过体检的同学，需携带本国医院提供的体检报告原件(中文或英文)到北京市出入境检验检疫局保健中心进行验证。</p>
        <p class="_t">健康检查所需的材料</p>
        <p>详情请咨询</p>
        <div class="tit">办理签证所需要的材料</div>
        <p>详情请咨询</p>
        <div class="tit">签证办理方式简介 </div>
        <p>从申请办理签证至获得新签证期间，住宿地址不能变更。如计划更换住址，请在申请办理签证前说明，以免造成不便，延误签证办理时间。</p>
        <p class="_t">签证办理有两种方式：</p>
        <p class="_t _ml">正常办理</p>
        <p class="_ml">学生服务大厅申请→本人去出入境管理处递交材料→出入境管理处取护照</p>
        <p class="_ml">携带办理签证需要的所有材料到学生服务中心提出申请</p>
        <p class="_ml">老师帮助您检查材料并且领取正式的签证申请表</p>
        <p class="_ml _b">学校留学生办公室给相关材料盖章（非常重要，否则出入境管理处将无法受理）</p>
        <p class="_ml _b">住校内的学生需要盖章的材料有：签证申请表第二页左下角，临时住宿登记表</p>
        <p class="_ml _b">住校外的学生需要盖章的材料有：签证申请表第二页左下角</p>
        <p class="_ml">本人携带护照和所有材料前往北京市公安局出入境管理处（东城区安定门东大街 2 号）办理</p>
        <p class="_ml">收到签证受理回执</p>
        <p class="_ml">按照规定时间（自受理之日起，签证和停留证件 7 个工作日办结，居留证件 10 个工作日办结）再次前往北京市公安局出入境管理处缴纳费用并取回护照，具体价格以北京市出入境管理处规定为准。</p>
        <p class="_ml">签证办理期间可以凭受理回执在中国境内乘坐飞机、火车，入住酒店。</p>
        <div class="tit">其他特殊情况的解决方案 </div>
        <p class="_t">护照到期更换新护照 </p>
        <p>在中国境内更换护照且持居留证件的同学在取得新护照十天之内必须办理居留证件更新手续</p>
        <p>在境外取得新护照的同学在入境时需要向中国海关出示新旧两本护照，并在新护照上盖入境章。</p>
        <p>取得新护照后立即办理新的临时住宿登记表（用新护照号码登记）</p>
        <p>所需材料：</p>
        <p>——新旧两本护照</p>
        <p>——2 寸（35mm×49mm）大小的近期半身正面免冠彩色照片</p>
        <p>——临时住宿登记表（必须使用新护照号码并盖章）</p>
        <p>——签证申请表（需盖章）</p>
        <p class="_t">丢失护照</p>
        <p>派出所办理《护照报失证明》</p>
        <p>大使馆申请办理新护照</p>
        <p>取得新护照后立即办理新的临时住宿登记表</p>
        <p>携带所需材料前往北京市出入境管理处办理</p>
        <p class="_t">所需材料：</p>
        <p>——护照</p>
        <p>——2 寸（35mm×49mm）大小的近期半身正面免冠彩色照片</p>
        <p>——临时住宿登记表</p>
        <p>——签证申请表（需盖章）</p>
        <p>——护照报失证明</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data () {
    return {
      activeName: 'first',
      tableData1: [
        {
          val1: '',
          val2: '',
          val3: '0',
          val4: '0',
          val5: '不会说汉语，也听不懂汉语',
          val6: 'RMB',
          val7: 'USD',
        },
        {
          val1: 'ZX1',
          val2: '1.5个月以后（40课时）',
          val3: 'A1',
          val4: 'HSK1',
          val5: '学会基本的生存汉语，能理解和使用汉语回答一些简单的问题，掌握150个常用词汇',
          val6: '￥999',
          val7: '$149',
        },
        {
          val1: 'ZX2',
          val2: '3个月以后（40课时）',
          val3: 'A2',
          val4: 'HSK2',
          val5: '可以用汉语进行日常对话，就日常生活、工作、教育、文化、感觉等常见话题进行简单而直接的交流，掌握300个常用词汇',
          val6: '￥999',
          val7: '$149',
        },
        {
          val1: 'ZX3',
          val2: '6个月以后（80课时）',
          val3: 'B1',
          val4: 'HSK3',
          val5: '可以与汉语为母语者以一般语速正常交流，话题扩展到科技、自然、家居、交往等各个方面，并能熟练使用约800个常用汉字阅读简单无拼音标注的文章以及用汉语和朋友们发送短信和微信信息等，词汇量达到600',
          val6: '￥1499',
          val7: '$229',

        },
        {
          val1: 'ZX4',
          val2: '10个月以后（120课时）',
          val3: 'B2',
          val4: 'HSK4',
          val5: '可以用汉语谈论经济、新闻、医疗、现象、就业等较为复杂的话题，表达更加规范和得体，能认识的汉字也更多，词汇量达到1200',
          val6: '￥3999',
          val7: '$599',
        },
        {
          val1: 'ZX5',
          val2: '15个月以后（180课时）',
          val3: 'C1',
          val4: 'HSK5',
          val5: '可以用汉语就文化、地理、体验感悟、文学艺术、金融、贸易等比较抽象或专业的话题进行讨论、评价和发表看法，较轻松地应对各种交际任务，同时也能阅读一般性的新闻和用汉语进行文书写作，词汇量达到2500',
          val6: '￥7299',
          val7: '$1060',
        },
        {
          val1: 'ZX6',
          val2: '22个月以后（280课时）',
          val3: 'C2',
          val4: 'HSK6',
          val5: '可以用汉语自如地进行各种社会交际活动，使用成语、俚语和其他习惯性表达，注意语体和语气，能进行更为专业的阅读和撰写各类文章，汉语应用水平接近汉语为母语者，词汇量达到5000',
          val6: '￥11999',
          val7: '$1760',
        },
      ],
      tableData2: [
        {
          val1: '',
          val2: '',
          val3: '0',
          val4: '0',
          val5: '不会说汉语，也听不懂汉语',
          val6: 'RMB',
          val7: 'USD',
        },
        {
          val1: 'ZXC1',
          val2: '2个月以后（50课时）',
          val3: 'A1',
          val4: '400汉字',
          val5: '学会基本的生存汉语，能理解和使用汉语回答一些简单的问题，掌握150个常用词汇',
          val6: '￥1249',
          val7: '$189',
        },
        {
          val1: 'ZXC2',
          val2: '4.5个月以后（60课时）',
          val3: 'A2',
          val4: '800汉字',
          val5: '可以基本看懂和编写日常汉语邮件、短信和微信内容，轻松地与中国亲朋好友、同事、客户、服务员、家政人员等进行书面交流，菜单、名片、车站显示屏上的汉字也可以轻松搞定，在中国生活不再困难，汉语达到HSK3级水',
          val6: '￥1499',
          val7: '$229',
        },
      ],
      tableData3: [
        {
          val1: '',
          val2: '',
          val3: '0',
          val4: '0',
          val5: '不会说汉语，也听不懂汉语',
          val6: 'RMB',
          val7: 'USD',
        },
        {
          val1: 'ZXB1',
          val2: '3个月以后（60课时）',
          val3: 'B1',
          val4: 'BCT(A)',
          val5: '可用汉语进行一般性商务交流，包括介绍商品、祝愿、感谢、道别、应聘等，也可看懂会议通知、差旅安排以及简单的报价单、收支表、日程表、海关表、签证表等；学会一些简单的中国式商务礼仪和正式场合的表达方式；掌握...',
          val6: '￥1499',
          val7: '$229',
        },
        {
          val1: 'ZXB2',
          val2: '9个月以后（200课时）',
          val3: 'C1',
          val4: 'BCT(B)',
          val5: '可以自如地应对各种商务场合，进行专业的、地道的商务汉语交际；能理解、分析和评论各种财经、科技新闻；能看懂和拟写投诉信、简历、求职信、合同、项目报告、述职报告、商务报表、规章制度等各类正式商务文本；能参...',
          val6: '￥11999',
          val7: '$1760',
        },
      ]
    }
  },
  methods: {
    tableRowClassName ({ rowIndex }) {
      if (rowIndex === 0) {
        return 'warning-row'
      } else if (rowIndex === 1) {
        return 'warning-row2'
      }
      else if (rowIndex === 2) {
        return 'warning-row3'
      }
      else if (rowIndex === 3) {
        return 'warning-row4'
      }
      else if (rowIndex === 4) {
        return 'warning-row5'
      }
      else if (rowIndex === 5) {
        return 'warning-row6'
      }
      else if (rowIndex === 6) {
        return 'warning-row7'
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner2.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.cont {
  .tit {
    border-left: 8px solid #0c3879;
    padding-left: 10px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
    line-height: 1;
  }
  .el-tabs ::v-deep {
    margin-top: 20px;
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
    .el-tabs__item.is-active,
    .el-tabs__item:hover {
      color: #0c3879;
    }
    .el-tabs__item {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .ps {
    text-indent: 2em;
    line-height: 1.7;
  }

  ._t {
    font-size: 16px;
    font-weight: bold;
    text-indent: 14px;
  }
  .pian {
    margin-top: 60px !important;
  }

  .text-cen {
    text-align: center;
    line-height: 1.8;
  }
  p {
    margin-bottom: 15px;
    text-indent: 28px;
    line-height: 1.7;
    font-size: 14px;
  }
  ._b {
    font-weight: bold;
  }
  ._ml {
    margin-left: 28px;
  }
  ._ml2 {
    margin-left: 56px;
  }
  p.text-cen {
    font-size: 16px;
    color: #484848;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  h3.text-cen {
    font-size: 20px;
    span {
      display: block;
      color: #0c3879;
      font-size: 30px;
    }
  }
}
</style>